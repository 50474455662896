import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from '@iso/redux/store';
import Boot from '@iso/redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <Provider store={store}>
      {/* <GoogleOAuthProvider clientId="91217139595-66pl44d4apcii8bjigej206dgmcbni20"> */}
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
      {/* </GoogleOAuthProvider> */}
    </Provider>
  );
}
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
